
// Toggle mobile menu.
const hamburger = document.getElementById('hamburger');
hamburger.addEventListener('click', function() {
    document.body.classList.toggle('show-menu');
}, false);

// Hero carousel.
const hero = document.getElementById('hero');
if (hero) {
    const slides = hero.querySelectorAll('.hero__slides__slide');
    // Autoplay hero slides if more than one slide exists.
    if (slides.length >= 2) {
        const slidesContainer = hero.querySelector('.hero__slides');
        const speed = slidesContainer.getAttribute('data-autoplay-speed');
        let curr = 1;
        const viewSlide = function(i){
            [].forEach.call(slides, function(el) {
                el.classList.remove('current');
            });
            slides[i-1].classList.add('current');
            curr = i;
        }
        const goNext = function(){
            let i = curr + 1;
            if (i > slides.length) {
                i = 1;
            }
            viewSlide(i);
        }
        viewSlide(1);
        const autoPlayTimer = setInterval(goNext, Number(speed));
    }
}

// Animated Circles.
const animatedCircles = document.getElementById('animated-circles');
if (animatedCircles) {

    animatedCircles.classList.add('animated-circles--will-animate');

    // Cache DOM elements that will animate.
    const circles = animatedCircles.querySelectorAll('.animated-circles__items__item');
    const circle1Bg = circles[0].querySelector('.animated-circles__items__item__bg');
    const circle2Bg = circles[1].querySelector('.animated-circles__items__item__bg');
    const circle3Bg = circles[2].querySelector('.animated-circles__items__item__bg');
    const centerText = animatedCircles.querySelector('.animated-circles__centre-text');

    // Init ScrollMagic controller.
	var controller = new ScrollMagic.Controller();

    /**
     * Create GSAP timeline.
     */
    const tl = gsap.timeline();
    // Animate into positions.
    tl.to(circles[0],   {duration: 0.8, y: '-70%', x: '-50%'});
    tl.to(circles[1],   {duration: 0.8, y: '60%', x: '-=70%'}, "-=0.5");
    tl.to(circles[2],   {duration: 0.8, y: '60%', x: '-=30%'}, "-=0.5");
    tl.add('circles-in-position')
    // Scale up background to overlap.
    tl.to(circle1Bg,    {duration: 0.4, scale: 1.4, y: "15%"});
    tl.to(circle2Bg,    {duration: 0.4, scale: 1.4, x: "10%", y: "-10%"});
    tl.to(circle3Bg,    {duration: 0.4, scale: 1.4, x: "-10%", y: "-10%"});
    tl.add('circles-backgrounds-scaled')
    // Reveal centre text.
    tl.to(centerText,   {duration: 1.2, scale: 1.2})

    // Attach GSAP timeline to ScrollMagic scene.
    var scene = new ScrollMagic.Scene({
        triggerElement: '#animated-circles'
        // triggerHook: 0.4,
        // duration: "70%",
    })
    .setTween(tl)
    .offset(circles.offsetHeight/2)
    .addTo(controller);
}

// Job List.
const jobList = document.getElementById('job-list');
if (jobList) {

    const blockTitle = document.getElementById('job-list-title');
    const allJobs = jobList.querySelectorAll('.job-list__job-listings__list-item');

    const jobFilters = jobList.querySelectorAll('.job-list__job-categories__job-category__button');
    jobFilters.forEach(function(filter) {
        filter.addEventListener('click', function(e) {
            e.preventDefault();

            const filterLabel = e.target.textContent;
            const filterKey = e.target.dataset.key;

            jobFilters.forEach(function(f) {
                if (f.classList.contains('selected')) {
                    f.classList.remove('selected');
                }
            });

            blockTitle.textContent = filterLabel + 'jobs';
            allJobs.forEach(function(job) {
                if (job.dataset.key === filterKey) {
                    job.classList.remove('hidden');
                } else {
                    job.classList.add('hidden');
                }
            });

            e.target.classList.add('selected');

        }, false);
    });
}

// Sliding testimonials
const testimonials = document.querySelector('[data-sliding-testimonials]');
if(testimonials) {
    const testimonialsSlider = new Splide(testimonials, {
        type    : 'loop',
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        drag: false,
        pagination : false,
        arrows : false,
        interval: 10000,
        speed: 2000,
    } ).mount();
}